<template>
  <v-card class="essay-viewer" flat :outlined="outlined">
    <div
      v-for="(item, i) in essayContentArray"
      :key="i"
      class="essay-viewer-item mb-4"
    >
      <div v-if="item.tagName === 'P'" v-html="item.innerHTML"></div>
      <div v-else-if="item.tagName === 'IMG'">
        <img
          :src="essayContent.files[getFileUuidAndExt(item.src)]"
          class="essay-image"
        />
      </div>
      <div v-else-if="item.tagName === 'VIDEO'">
        <video
          id="video-element"
          controls
          class="essay-video"
          :is-video="true"
          :src="essayContent.files[getFileUuidAndExt(item.src)]"
        >
          Your browser does not support the video element.
        </video>
      </div>
      <div v-else-if="item.tagName === 'AUDIO'">
        <audio
          controls
          class="essay-audio"
          :src="essayContent.files[getFileUuidAndExt(item.src)]"
        >
          Your browser does not support the audio element.
        </audio>
      </div>
      <h1 v-else-if="item.tagName === 'H1'" v-html="item.innerHTML"></h1>
      <h2 v-else-if="item.tagName === 'H2'" v-html="item.innerHTML"></h2>
      <h3 v-else-if="item.tagName === 'H3'" v-html="item.innerHTML"></h3>
      <h4 v-else-if="item.tagName === 'H4'" v-html="item.innerHTML"></h4>
      <h5 v-else-if="item.tagName === 'H5'" v-html="item.innerHTML"></h5>
      <h6 v-else-if="item.tagName === 'H6'" v-html="item.innerHTML"></h6>
      <div v-else-if="item.tagName === 'DIV'" v-html="item.innerHTML"></div>
    </div>
  </v-card>
</template>

<script>
export default {
  name: "EssayViewer",
  props: {
    essayContent: {
      required: true,
      type: Object
    },
    outlined: {
      type: Boolean,
      default: true
    },
    reactive: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      essayContentArray: [],
      dummy: undefined,
      renderNow: false
    };
  },
  watch: {
    essayContent: {
      deep: true,
      handler() {
        if(this.reactive) this.renderBody();
      }
    }
  },
  created() {
    this.renderBody();
  },
  methods: {
    getFileUuidAndExt(itemSrc) {
      return itemSrc.split("/").pop();
    },
    renderBody() {
      const parser = new DOMParser();
      // the following || was used to expect either item_text or text
      let parsed = parser.parseFromString(
        this.essayContent.item_text || this.essayContent.text,
        "text/html"
      );
      this.essayContentArray = [];
      let allTags = parsed.querySelectorAll("*");
      for (let i = 0; i < allTags.length; i++) {
        let thisTag = allTags[i];
        if (!Array("HTML", "HEAD", "BODY").includes(thisTag.tagName)) {
          this.essayContentArray.push(thisTag);
        }
      }
    }
  }
};
</script>

<style scoped lang="scss">
.essay-viewer {
  display: block;
  justify-content: center;
  align-items: center;
  padding: 30px;
  @media screen and (max-width: 360px) {
    padding: 14px;
    width: 68vw;
  }
}

.essay-viewer-item {
  display: block;
  justify-content: left;
  text-align: left;
}

.essay-image {
  @media screen and (min-width: 1920px) {
    width: min(720px, 100%);
  }
  @media screen and (min-width: 1280px) and (max-width: 1919px) {
    width: min(700px, 100%);
  }
  @media screen and (min-width: 800px) and (max-width: 1279px) {
    width: 100%;
  }
  @media screen and (max-width: 799px) {
    // for 360px screen
    width: 100%;
  }
}

.essay-video {
  @media screen and (min-width: 1920px) {
    width: min(640px, 100%);
  }
  @media screen and (min-width: 1280px) and (max-width: 1919px) {
    width: min(640px, 100%);
  }
  @media screen and (min-width: 800px) and (max-width: 1279px) {
    width: 100%;
  }
  @media screen and (max-width: 799px) {
    // for 360px screen
    width: 100%;
  }
}

.essay-audio {
  @media screen and (min-width: 1920px) {
    width: min(640px, 100%);
  }
  @media screen and (min-width: 1280px) and (max-width: 1919px) {
    width: min(640px, 100%);
  }
  @media screen and (min-width: 800px) and (max-width: 1279px) {
    width: 100%;
  }
  @media screen and (max-width: 799px) {
    // for 360px screen
    width: 100%;
  }
}
</style>
