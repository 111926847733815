var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-card",
    {
      staticClass: "essay-viewer",
      attrs: { flat: "", outlined: _vm.outlined },
    },
    _vm._l(_vm.essayContentArray, function (item, i) {
      return _c("div", { key: i, staticClass: "essay-viewer-item mb-4" }, [
        item.tagName === "P"
          ? _c("div", { domProps: { innerHTML: _vm._s(item.innerHTML) } })
          : item.tagName === "IMG"
          ? _c("div", [
              _c("img", {
                staticClass: "essay-image",
                attrs: {
                  src: _vm.essayContent.files[_vm.getFileUuidAndExt(item.src)],
                },
              }),
            ])
          : item.tagName === "VIDEO"
          ? _c("div", [
              _c(
                "video",
                {
                  staticClass: "essay-video",
                  attrs: {
                    id: "video-element",
                    controls: "",
                    "is-video": true,
                    src: _vm.essayContent.files[
                      _vm.getFileUuidAndExt(item.src)
                    ],
                  },
                },
                [_vm._v(" Your browser does not support the video element. ")]
              ),
            ])
          : item.tagName === "AUDIO"
          ? _c("div", [
              _c(
                "audio",
                {
                  staticClass: "essay-audio",
                  attrs: {
                    controls: "",
                    src: _vm.essayContent.files[
                      _vm.getFileUuidAndExt(item.src)
                    ],
                  },
                },
                [_vm._v(" Your browser does not support the audio element. ")]
              ),
            ])
          : item.tagName === "H1"
          ? _c("h1", { domProps: { innerHTML: _vm._s(item.innerHTML) } })
          : item.tagName === "H2"
          ? _c("h2", { domProps: { innerHTML: _vm._s(item.innerHTML) } })
          : item.tagName === "H3"
          ? _c("h3", { domProps: { innerHTML: _vm._s(item.innerHTML) } })
          : item.tagName === "H4"
          ? _c("h4", { domProps: { innerHTML: _vm._s(item.innerHTML) } })
          : item.tagName === "H5"
          ? _c("h5", { domProps: { innerHTML: _vm._s(item.innerHTML) } })
          : item.tagName === "H6"
          ? _c("h6", { domProps: { innerHTML: _vm._s(item.innerHTML) } })
          : item.tagName === "DIV"
          ? _c("div", { domProps: { innerHTML: _vm._s(item.innerHTML) } })
          : _vm._e(),
      ])
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }